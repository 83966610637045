<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-container class="form-wrapper">
        <v-toolbar-title class="mb-6 sub-title"
          >Basic Information</v-toolbar-title
        >
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              label="First Name"
              placeholder="Enter First Name"
              v-model="data.first_name"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              label="Middle Name"
              placeholder="Enter Middle Name"
              v-model="data.middle_name"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              label="Last Name"
              placeholder="Enter Last Name"
              v-model="data.last_name"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              label="Address"
              placeholder="Enter Address"
              v-model="data.address"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <!-- </v-row>
        <v-row> -->

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              label="City"
              placeholder="Enter City"
              v-model="data.city"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              outlined
              label="Country"
              placeholder="Enter Country"
              v-model="data.country"
              :rules="[rules.required]"
              :items="countriesOption"
              item-text="name"
              item-value="iso3"
              @change="countryChanged"
              v-bind="basic"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-select
              outlined
              :items="stateOptions"
              item-text="name"
              item-value="state_code"
              label="State"
              v-model="data.state"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              label="Postal Code"
              placeholder="Enter Postal Code"
              v-model="data.postal_code"
              maxlength="4"
              :rules="[rules.required, rules.number, rules.postalLength]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <!-- </v-row>

        <v-row> -->
          <v-col class="d-flex" cols="12" sm="6" md="4">
            <v-select
              outlined
              :items="companyOptions"
              item-text="company_name"
              item-value="id"
              label="Company"
              v-model="data.company_id"
              :rules="[rules.required]"
              v-bind="basic"
            >
              <template #append-item>
                <v-row>
                  <v-col>
                    <v-item>
                      <v-btn
                        block
                        v-on:click="showAddCompanyDialog()"
                        class="item-button"
                        >Add Company</v-btn
                      >
                    </v-item>
                  </v-col>
                </v-row>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              label="Job Title"
              placeholder="Enter Job Title"
              v-model="data.job_title"
              :rules="[rules.required]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <!-- </v-row>

        <v-row> -->
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              label="Email"
              placeholder="Enter Email"
              v-model="data.email"
              :rules="[rules.required, rules.email]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              outlined
              label="Phone Number"
              placeholder="Enter Number"
              v-model="data.number"
              :rules="[rules.required, rules.number]"
              v-bind="basic"
            ></v-text-field>
          </v-col>
          <v-col class="d-flex" cols="12" sm="6" md="4">
            <v-select
              outlined
              :items="customerTypeOptions"
              item-text="name"
              item-value="id"
              label="Customer Type"
              v-model="data.customer_type_id"
              :rules="[rules.required]"
              v-bind="basic"
            >
              <template #append-item>
                <v-row>
                  <v-col>
                    <v-item>
                      <v-btn
                        block
                        v-on:click="showAddCustomerTypeDialog()"
                        class="item-button"
                        >Add Customer Type</v-btn
                      >
                    </v-item>
                  </v-col>
                </v-row>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <br />
        <v-row>
          <v-col>
            <v-btn
              color="white"
              elevation="2"
              large
              class="mb-2 submit-button"
              v-on:click="updateContact"
              block
              v-bind="basic"
              :loading="loading"
              >Update Contact</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-form>
    <v-dialog v-model="addCompany" max-width="600px">
      <addCompany @companyStored="companyStored" />
    </v-dialog>

    <v-dialog v-model="addCustomerType" max-width="600px">
      <addCustomerType @customerTypeStored="customerTypeStored" />
    </v-dialog>
  </div>
</template>

<script>
import util from "@/util/util";
import axios from "axios";
import addCompany from "@/components/Company/create";
import addCustomerType from "@/components/CustomerType/create";
export default {
  components: {
    addCompany,
    addCustomerType,
  },
  data() {
    return {
      loading: false,
      data: {},
      rules: util.validation,
      statusOptions: util.statusOptions,
      stateOptions: util.stateOptions,
      companyOptions: [],
      countriesOption: [],
      customerTypeOptions: [],
      addCompany: false,
      addCustomerType: false,
      basic: util.input.basic,
      id: this.$route.params.id,
    };
  },
  mounted() {
    this.fetchContact();
    this.fetchCompanies();
    this.fetchCustomerTypes();
    this.fetchCountry();
  },
  methods: {
    countryChanged(d) {
      let selectedCountry = this.countriesOption.find((c) => {
        return c.iso3 == d;
      });
      selectedCountry
        ? (this.stateOptions = selectedCountry.states)
        : (this.stateOptions = []);
    },
    fetchContact() {
      util
        .http({ url: "customer/" + this.$route.params.id, method: "get" })
        .then((res) => {
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            this.data = res.data.data;
            console.log(res);
          }
        });
    },
    fetchCompanies() {
      util.http({ url: "company/get-all", method: "get" }).then((res) => {
        if (res.data.status == 0) {
          util.notify(0, res.data.errors[0]);
        } else {
          this.companyOptions = res.data.data;
        }
      });
    },
    fetchCustomerTypes() {
      util.http({ url: "customer-type/get-all", method: "get" }).then((res) => {
        if (res.data.status == 0) {
          util.notify(0, res.data.errors[0]);
        } else {
          this.customerTypeOptions = res.data.data;
        }
      });
    },
    fetchCountry() {
      axios({
        url: "/static/country.json",
      })
        .then((resp) => {
          this.countriesOption = resp.data.data;
        })
        .catch();
    },
    updateContact() {
      this.$refs.form.validate();
      this.loading = true;
      util
        .http({
          url: "customer/" + this.$route.params.id,
          method: "put",
          data: this.data,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.status == 0) {
            util.notify(0, res.data.errors[0]);
          } else {
            util.notify(1, "Successfully Updated");
            this.$emit("reload", true);
            this.$router.push({
              path:
                "/contacts/" +
                this.$route.params.id +
                "/additional-information",
            });
          }
        });
    },
    showAddCompanyDialog() {
      this.addCompany = true;
    },

    showAddCustomerTypeDialog() {
      this.addCustomerType = true;
    },
    companyStored() {
      this.addCompany = false;
      this.fetchCompanies();
    },

    customerTypeStored() {
      this.addCustomerType = false;
      this.fetchCustomerTypes();
    },
  },
  watch: {
    "$route.params.id": function() {
      this.fetchContact();
    },
  },
};
</script>
